.rdp-caption_label {
  @apply text-lg;
}

.rdp-day_today {
  @apply text-secondary-green;
}

.rdp-day_range {
  @apply !bg-primary-green
}

.rdp-day_selected {
  @apply hover:!bg-secondary-green !bg-primary-green !text-white;
}

.rdp-button {
  @apply focus:!outline-secondary-green focus:!ring-secondary-green focus:!border-secondary-green;
}