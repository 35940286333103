@tailwind base;
@tailwind components;
@tailwind utilities;

.react-headless-notifier-top-0 {
  @apply w-full max-w-[611px] top-3 right-3 m-0;
}

body {
  font-family: 'Inter', sans-serif;
  @apply text-text-gray antialiased;
}

.searchbox-container {
  background-image: url('/public/images/hero-banner.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}